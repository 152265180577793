const tf = require('@tensorflow/tfjs');
const XLSX = require('xlsx');

function readExcelFile(file) {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject(new Error('No file uploaded'));
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
      resolve(jsonData);
    };
    reader.onerror = (error) => {
      reject(new Error('Error reading file:', error));
    };
    reader.readAsArrayBuffer(file);
  });
};

// Splits table data into data and labels
function splitTable(table, labelColumnName) {
  // Finds the index of the label column
  const headerRow = table[0];
  const labelColumnIndex = headerRow.indexOf(labelColumnName);
  console.log(headerRow)
  if (labelColumnIndex === -1) {
    throw new Error(`Column "${labelColumnName}" not found in headers.`);
  } 
  // Separate data and labels
  const data = [];
  const labels = [];
  for (let i = 1; i < table.length; i++) { // Start from i=1 to skip header row
    const row = table[i];
    const rowWithoutLabelColumn = row.slice(0, labelColumnIndex).concat(row.slice(labelColumnIndex + 1));
    data.push(rowWithoutLabelColumn);
    labels.push(row[labelColumnIndex]);
  }
  return { data: tf.tensor2d(data), labels: tf.tensor1d(labels) };
}

// Removes column if exists and returns the data
function removeColumn(table, columnName) {
  // Find the column index
  const headerRow = table[0];
  const labelColumnIndex = headerRow.indexOf(columnName);
    // Remove the column from the data
    const data = [];
    for (let i = 1; i < table.length; i++) { // Start from the second row to skip header row
      const row = table[i];
      data.push(row.slice(0, labelColumnIndex).concat(row.slice(labelColumnIndex + 1)));
    }
  return tf.tensor2d(data);
}

// Splits data and labels into training and validation
function trainValidationSplit(data, labels, trainRatio) {
    // Convert data to an array if it's not already
    const dataArray = Array.isArray(data) ? data : [data];
    // Check if the lengths of data and labels are the same
    if (dataArray[0].shape[0] !== labels.shape[0])
        throw new Error('Data and labels must have the same length')
    // Combine data and labels into a single array of objects
    const combinedData = dataArray[0].arraySync().map((value, index) => ({ data: value, label: labels.arraySync()[index] }));
    // Shuffle the data array using Fisher-Yates algorithm
    const shuffledData = [...combinedData];
    for (let i = shuffledData.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledData[i], shuffledData[j]] = [shuffledData[j], shuffledData[i]];
    }
    // Calculate the index to split the data into training and test sets
    const splitIndex = Math.floor((1 - trainRatio) * shuffledData.length);
    // Split the data into training and test sets
    const trainData = shuffledData.slice(0, splitIndex);
    const testData = shuffledData.slice(splitIndex);
    // Extract data and labels from the training and test sets
    const trainingData = tf.tensor2d(trainData.map(item => item.data));
    const trainingLabels =  tf.tensor1d(trainData.map(item => item.label).flat());
    const validationData = tf.tensor2d(testData.map(item => item.data));
    const validationLabels =  tf.tensor1d(testData.map(item => item.label).flat());

    return { trainingData, trainingLabels, validationData, validationLabels };
}

async function createResultFiles(predictionFileData, predictionResults) {
    console.log(await predictionResults)
    const results = await predictionResults
    await predictionFileData.forEach((row, index) => {
      if (index === 0) {
        row.push('Prediction');
      } else {
        row.push(results[index - 1]);
      }
    });
    const workbook = XLSX.utils.book_new();
    const sheet = XLSX.utils.aoa_to_sheet(await predictionFileData);
    XLSX.utils.book_append_sheet(workbook, sheet, 'Predictions');
    const book = XLSX.writeFile(workbook, 'predictions_with_results.xlsx');
    return book
};

// used for rebuilding excel files after they are fetch from local storage
async function FileRebuilder(selectedFileData){
  if (selectedFileData) {
    try {
      const arrayBuffer = new Uint8Array(selectedFileData.content.length);
      for (let i = 0; i < selectedFileData.content.length; i++) {
        arrayBuffer[i] = selectedFileData.content.charCodeAt(i) & 0xff;
      }
      const recreatedFile = new File([arrayBuffer], selectedFileData.name, { type: 'application/octet-stream', lastModified: new Date(selectedFileData.date).getTime() });
      return recreatedFile;
    } catch (error) {
      console.error('Error recreating file:', error);
      return null;
    }
  } else {
    return null;
  }
}

// calculates the absolute average difference between two arrays in precentages
async function calcAvgAccInPrecentages(actual, predicted) {
  if (actual.length !== predicted.length) {
    throw new Error('Arrays must be of the same length');
  }

  let totalDifference = 0;

  for (let i = 0; i < actual.length; i++) {
    const absoluteDifference = Math.abs(actual[i] - predicted[i]);
    let percentageDifference = 0;

    if (actual[i] !== 0) {
      percentageDifference = (absoluteDifference / actual[i]) * 100;
    }

    totalDifference += percentageDifference;
  }

  const averagePercentageDifference = totalDifference / actual.length;
  return (100 - averagePercentageDifference).toFixed(3);
}

module.exports = {
    trainValidationSplit,
    createResultFiles,
    splitTable,
    readExcelFile,
    removeColumn,
    FileRebuilder,
    calcAvgAccInPrecentages
}