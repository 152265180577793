import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-white"  style={{ padding: '10px', textAlign: 'center', color: 'white' }}>
      {/* TODO: Set footer text in <p> */}
      <p></p>
    </footer>
  );
};

export default Footer;
