import React from 'react';
import DataTable from '../Components/Tables/DataTable';
import PredictionTable from '../Components/Tables/PredictionTable';

const PredictPage = () => {
    return (
        <div className='text-center'>
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mt-10 mb-2">Prediction Data</h1>
            <DataTable predictionDataBool={true}/>
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mt-10 mb-2">Predictions</h1>
            <PredictionTable/>
        </div>
    );
};

export default PredictPage;