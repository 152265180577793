import React, { useState } from 'react';
import { MODEL_STORAGE_PATH } from '../../config';
import Model from '../../tensorflow/Model';
import utils from '../../tensorflow/utils';
import { useNavigate } from 'react-router-dom';

const TrainingForm = () => {
  const [trainingType, setTrainingType] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [epochProgress, setEpochProgress] = useState({ current: 0, total: 0 });
  const [epochLoss, setEpochLoss] = useState(0);
  const [modelName, setModelName] = useState('');
  const [epochNum, setEpochNum] = useState('');
  const [batchSize, setBatchSize] = useState('');
  const [trainingValidationSplit, setTrainingValidationSplit] = useState('');
  const [threshold, setThreshold] = useState('');
  const [takenName, setTakenName] = useState(false);
  const navigate = useNavigate();

  const handleTrainingTypeChange = (e) => {
    const value = e.target.value;
    setTrainingType(value);
    if (value === 'fast') {
      setEpochNum('100');
      setBatchSize('10');
      setTrainingValidationSplit('0.2');
      setThreshold('0.5');
    } else if (value === 'full') {
      setEpochNum('300');
      setBatchSize('20');
      setTrainingValidationSplit('0.4');
      setThreshold('0.2');
    }
  };

  const handleTrainModel = async () => {
    try {
      setTakenName(false)
      if (!selectedFile) {
        throw new Error('No file selected');
      }
      if(!modelName) {
        throw new Error('No model name');
      }
      const trainingData = await utils.readExcelFile(selectedFile);
      const numOfEpochs = parseInt(epochNum);
      const trainingBatchSize = parseInt(batchSize);
      const accThreshold = parseFloat(threshold);
      const trainingValidation = parseFloat(trainingValidationSplit);
      const uploadedModels = JSON.parse(localStorage.getItem('savedModels'));
      let selectedModel;
      if (uploadedModels) {
        selectedModel = uploadedModels.find(file => file.name === modelName);
      }
      if (!selectedModel) { // If there is no model with the same name
        const { model , testAccuracy , runningTimeInMin } = await Model.train(numOfEpochs, trainingData, updateEpochProgress, updateEpochLoss, trainingBatchSize, accThreshold, trainingValidation);
        await model.save(MODEL_STORAGE_PATH + modelName);
        const modelInfo = { 
          name: modelName, 
          date: new Date().toLocaleDateString(), 
          trainingFileName: selectedFile.name, 
          trainingTime: runningTimeInMin + ' Min', 
          accuracy: testAccuracy + '%'
        };
        const savedModels = JSON.parse(localStorage.getItem('savedModels')) || [];
        localStorage.setItem('savedModels', JSON.stringify([...savedModels, modelInfo]));
      } else {
        setTakenName(true);
      }
    } catch (e) {
      console.error(e);
      alert('Error training model: ' + e.message);
    }
  };

  const updateEpochLoss = (loss) => {
    setEpochLoss(loss);
  };
    
  const handleFileChange = async (e) => {
    const selectedFileName = e.target.value;
    const selectedFileData = uploadedFiles.find(file => file.name === selectedFileName);
    setSelectedFile(await utils.FileRebuilder(selectedFileData))
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setTrainingType('');
    setSelectedFile(null);
  };

  const uploadedFiles = localStorage.getItem('uploadedFiles')
    ? JSON.parse(localStorage.getItem('uploadedFiles'))
    : [];

  const updateEpochProgress = (currentEpoch, totalEpochs) => {
    setEpochProgress({ current: currentEpoch, total: totalEpochs });
  };

  return (
    <div className="flex items-center justify-center w-full mt-6">
      <div className="bg-slate-300 max-w-6xl md:rounded-lg py-8 px-10 lg:mx-4 text-gray-800 flex flex-column items-start text-gray-600" >
        <form onSubmit={handleSubmit}>
          <div className="flex flex-column items-start text-md">
            <p className='text-md font-medium max-w-3xl text-start'>When working with training files containing less than 50 rows, consider using a smaller batch size for optimal results.</p>
              
            <div className="pt-2">
              <label htmlFor="text" className="block text-sm font-medium leading-6 text-gray-900">
                Model Name
              </label>
              <input
                type="text"
                id="text"
                className="mt-1 rounded-md border-0 bg-white py-1 px-2 outline-none text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                value={modelName}
                onChange={(e) => setModelName(e.target.value)}
              />
            </div>

            <div className='pt-4 pb-1'>
              <label htmlFor="trainingType" className="block text-sm font-medium leading-6 text-gray-900">
                Training Type
              </label>
              <select
                id="trainingType"
                value={trainingType}
                onChange={handleTrainingTypeChange}
                className="mt-1 block w-full rounded-md border-0 bg-white py-2 pl-2 pr-10 text-gray-900 ring-1 ring-inset ring-gray-200 outline-none focus:ring-1 focus:ring-blue-600 sm:text-sm sm:leading-6"
              >
                <option value="">Select Training Type</option>
                <option value="fast">Fast Training</option>
                <option value="full">Full Training</option>
                <option value="custom">Custom Training</option>
              </select>
            </div>

            <div className='pt-2'>
              <label htmlFor="epochNum" className="text-sm font-medium leading-6 text-gray-900">Epoch Number: &ensp;</label>
              <input
                type="text"
                id="epochNum"
                value={epochNum}
                onChange={(e) => setEpochNum(e.target.value)}
                disabled={trainingType !== 'custom'}
                className="rounded-md px-2 py-1 outline-none"
              />
            </div>
            <div className='pt-2'>
              <label htmlFor="batchSize" className="text-sm font-medium leading-6 text-gray-900">Batch Size: &ensp;</label>
              <input
                type="text"
                id="batchSize"
                value={batchSize}
                onChange={(e) => setBatchSize(e.target.value)}
                disabled={trainingType !== 'custom'}
                className="rounded-md px-2 py-1 outline-none"
                />
            </div>
            <div className='pt-2'>
              <label htmlFor="threshold" className="text-sm font-medium leading-6 text-gray-900">Threshold: &ensp;</label>
              <input
                type="text"
                id="threshold"
                value={threshold}
                onChange={(e) => setThreshold(e.target.value)}
                disabled={trainingType !== 'custom'}
                className="rounded-md px-2 py-1 outline-none"
              />
            </div>
            <div className='pt-2'>
              <label htmlFor="trainingSplit" className="text-sm font-medium leading-6 text-gray-900">Training Validation Split: &ensp;</label>
              <input
                type="text"
                id="trainingSplit"
                value={trainingValidationSplit}
                onChange={(e) => setTrainingValidationSplit(e.target.value)}
                disabled={trainingType !== 'custom'}
                className="rounded-md px-2 py-1 outline-none"
              />
            </div>

            <div className="mt-4 mb-3">
              <label htmlFor="file" className="block text-sm font-medium leading-6 text-gray-900">
                Select File
              </label>
              <select
                id="file"
                className="mt-1 block w-full rounded-md border-0 bg-white py-2 pl-2 pr-10 text-gray-900 ring-1 ring-inset ring-gray-200 outline-none focus:ring-1 focus:ring-blue-600 sm:text-sm sm:leading-6"
                onChange={handleFileChange}
                >
                <option value="">Select File</option>
                {uploadedFiles.map((file, index) => (
                  <option key={index} value={file.name}>
                  {file.name} - {file.date}
                  </option>
                ))}
              </select>
            </div>

            <button
              type="button"
              className="block rounded-md bg-blue-600 mt-3 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              onClick={handleTrainModel}>
                Train
            </button>
            <div className="pt-1 text-sm">
              Epoch: {epochProgress.current}/{epochProgress.total}, Loss: {epochLoss}
            </div>

            {takenName && (
              <h2 className='text-base leading-4 text-red-500 mt-3'>The model name you entered is already in use. Please choose a different name.</h2>
            )}
            {epochProgress.current === epochProgress.total && epochProgress.current > 0 &&(
              <button
                type="button"
                className="block rounded-md bg-blue-600 mt-4 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 self-center"
                onClick={() => navigate('/train')}
              >
                Return to Table
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default TrainingForm;
