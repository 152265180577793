import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Components/Navbars & Footers/Navbar';
import Home from './Pages/Home';
import Footer from './Components/Navbars & Footers/Footer';
import DataViewPage from './Pages/DataViewPage';
import TrainingForm from './Components/Forms/TrainingForm';
import PredictPage from './Pages/PredictPage';
import PredictionForm from './Components/Forms/PredictionForm';
import ResultsPage from './Pages/ResultsPage';
import TrainPage from './Pages/TrainPage';
import ReactGA from 'react-ga4';


const App = () => {
  ReactGA.initialize('G-2F37RHP7KY');
  const style={ height: '100%' }
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    handleResize(); 
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

return (
    <div className="App">
      <Router>
        <div className="wrapper" style={style}>
          <Navbar />
          <div className="content flex flex-column pt-20" style={style}>
            {isMobile && <div className="mt-2 mx-4 p-1 max-w-sm text-center text-[#8f5763] bg-slate-50 text-md border-1 border-[#ced0d4] rounded-lg self-center">For optimal viewing, switch to a device with a larger screen</div>}
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/train" element={<TrainPage />} />
              <Route path="/train/data-view" element={<DataViewPage />} />
              <Route path="/train/training-form" element={<TrainingForm />} />
              <Route path="/predict" element={<PredictPage />} />
              <Route path="/predict/data-view" element={<DataViewPage />} />
              <Route path="/predict/prediction-form" element={<PredictionForm />} />
              <Route path="/predict/prediction-results" element={<ResultsPage />} />
            </Routes>
          </div>
        </div>
        <Footer />
      </Router>
    </div>
  );
}

export default App;

// TODO:
// * Consider adding training-results page 
// * V Lower setExcelFile to each page separately
// * Consider changing localStorage to IndexedDB which is not limited to 5-10MB