import React, { useState, useEffect } from 'react';
import * as tf from '@tensorflow/tfjs';
import TrainingTableRow from './TrainingTableRow'; 
import { MODEL_STORAGE_PATH } from '../../config';
import { useNavigate } from 'react-router-dom';

const TrainingTable = () => {
  const [models, setModels] = useState([]);
  const navigate = useNavigate();

  const trainModel = () => {
    navigate('/train/training-form');
  };

  const handleRemoveModel = async (modelNameToRemove) => {
    try {
      await tf.io.removeModel(`${MODEL_STORAGE_PATH}${modelNameToRemove}`);
      const savedModels = JSON.parse(localStorage.getItem('savedModels'));
      const updatedModels = savedModels.filter(model => model.name !== modelNameToRemove);
      localStorage.setItem('savedModels', JSON.stringify(updatedModels));
      setModels(updatedModels);
    } catch (error) {
      console.error('Error removing model:', error);
    }
  };

  useEffect(() => {
    const savedModels = JSON.parse(localStorage.getItem('savedModels')) || [];
    setModels(savedModels);
  }, []);

  return (
    <div className="my-2 flex flex-column max-w-6xl mx-auto items-center">
      <div className="inline-block min-w-full w-full py-4 align-middle sm:px-6 lg:px-8">
        <div className="overflow-x-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                <span className="sr-only">Row ID</span>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Model Name
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Date
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Training Time
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Training Accuracy
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Training File
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4">
                  <span className="sr-only">Remove</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {models.map((model, index) => (
                <TrainingTableRow
                 key={index}
                 modelName={model.name}
                 modelDate={model.date}
                 trainingTime={model.trainingTime}
                 accuracy={model.accuracy} 
                 fileName={model.trainingFileName} 
                 removeModel={handleRemoveModel}
                 index={index + 1}
               />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <button className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        onClick={trainModel}>
          Train a New Model
      </button>
    </div>
  );
};

export default TrainingTable;
