import React, { useState } from 'react';
import * as tf from '@tensorflow/tfjs';
import { PencilSquareIcon } from '@heroicons/react/24/outline'

const TrainingTableRow = ({ modelName, modelDate, trainingTime, accuracy, fileName: trainingFileName, removeModel, index }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedModelName, setEditedModelName] = useState(modelName);

  const handleRemoveModel = () => {
    removeModel(modelName);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSaveModel = async () => {
    try {
      const savedModels = JSON.parse(localStorage.getItem('savedModels')) || [];
      const updatedSavedModels = savedModels.map(model => {
        if (model.name === modelName) {
          return { ...model, name: editedModelName };
        }
        return model;
      });
      localStorage.setItem('savedModels', JSON.stringify(updatedSavedModels));
      setIsEditing(false);
      const model = await tf.loadLayersModel(`localstorage://./Models/${modelName}`);
      model.modelName = editedModelName;
      await model.save(`localstorage://./Models/${editedModelName}`);
      await tf.io.removeModel(`localstorage://./Models/${modelName}`);
    } catch (error) {
      console.error("Error updating model:", error);
    }
  };

  const handleCancelEditing = () => {
    setEditedModelName(modelName);
    setIsEditing(false);
  };

  const handleModelNameChange = (e) => {
    setEditedModelName(e.target.value);
  };

  return (
    <tr key={index}>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">{index}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 w-[330px] text-left">
        {isEditing ? (
          <div className="flex items-center justify-between">
            <input
              className="w-[180px] outline-none block w-full rounded-md border-0 px-2 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              value={editedModelName}
              onChange={handleModelNameChange}
            />
            <div className="flex items-center">
              <button className="text-blue-600 hover:text-blue-900 mx-1 px-1" onClick={handleSaveModel}>
                Save
              </button>
              <button className="text-red-600 hover:text-red-900 mx-1 px-1" onClick={handleCancelEditing}>
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <div className="flex items-center">
            {editedModelName}
            <button className="btn" data-twe-toggle="tooltip" title="Edit Model Name" onClick={handleEdit}>
              <PencilSquareIcon className="w-5 h-5" alt="Edit" />
            </button>
          </div>
        )}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">{modelDate}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">{trainingTime}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">{accuracy}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">{trainingFileName}</td>
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 text-left">
        <button className="text-red-600 hover:text-red-900 mx-1 px-1" onClick={handleRemoveModel}>
          Remove
        </button>
      </td>
    </tr>
  );
};

export default TrainingTableRow;
