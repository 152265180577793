import React from 'react';
import TrainingTable from '../Components/Tables/TrainingTable';
import DataTable from '../Components/Tables/DataTable';

const TrainPage = () => {
    return (
        <div style={{textAlign: 'center'}}>
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mt-10 mb-2">Training Data</h1>
            <DataTable predictionDataBool={false} />
            <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl mt-14 mb-2">Training Runs</h1>
            <TrainingTable/>
        </div>
    );
};

export default TrainPage;