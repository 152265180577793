import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { useLocation } from 'react-router-dom';

const DataViewPage = () => {
  const [tableData, setTableData] = useState([]);
  const location = useLocation();
  const { excelFile } = location.state;

  useEffect(() => {
    if (excelFile instanceof File) {
      const reader = new FileReader();
      reader.onload = function(e) {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0]; 
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        setTableData(jsonData);
      };
      reader.readAsArrayBuffer(excelFile);
    }
  }, [excelFile]);

  return (
    <div className="my-2 overflow-x flex flex-column items-start">
      <div className="inline-block min-w-full py-4 align-middle sm:px-6 lg:px-8">
        <div className="shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                {tableData.length > 0 && tableData[0].map((cell, index) => (
                  <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900" key={index}>{cell}</th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {tableData.slice(1).map((row, rowIndex) => ( 
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={`${rowIndex}-${cellIndex}`} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">{cell}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DataViewPage;
