import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import { useLocation } from 'react-router-dom';
import utils from '../tensorflow/utils';

const ResultsPage = () => {
  const [tableData, setTableData] = useState([]);
  const location = useLocation();
  const { excelFile, predictionArray} = location.state;

  useEffect(() => {
    if (excelFile instanceof File) {
      const reader = new FileReader();
      reader.onload = function(e) {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0]; 
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        setTableData(jsonData);
      };
      reader.readAsArrayBuffer(excelFile);
    }
  }, [excelFile]);

  const downloadResults = async () => {
    const file = excelFile;
    const readFile = await utils.readExcelFile(file)
    await utils.createResultFiles(readFile, predictionArray)
  };

  return (
    <div className="my-2 overflow-x flex flex-column items-start">
      <div className="inline-block min-w-full py-4 align-middle sm:px-6 lg:px-8">
        <div className="shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                {tableData.length > 0 && tableData[0].map((cell, index) => (
                  <th key={index} className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">{cell}</th>
                ))}
                <th className="py-3.5 px-3 text-left text-sm font-bold text-gray-900">Prediction</th> 
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {tableData.slice(1).map((row, rowIndex) => ( 
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) => (
                    <td key={`${rowIndex}-${cellIndex}`} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">{cell}</td>
                  ))}
                  <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-600 text-left">{predictionArray[rowIndex]}</td> 
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <button className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 self-center" onClick={() => downloadResults()}>
        Download
      </button>
    </div>
  );
};

export default ResultsPage;
