import React, { useState, useEffect } from 'react';
import { MODEL_STORAGE_PATH, LABEL_COLUMN_NAME } from '../../config';
import * as tf from '@tensorflow/tfjs';
import Model from '../../tensorflow/Model';
import utils from '../../tensorflow/utils';
import { useNavigate } from 'react-router-dom';

const PredictionForm = () => {
  const [choosenModelName, setChoosenModelName] = useState('');
  const [choosenFile, setChoosenFile] = useState('');
  const [models, setModels] = useState([]);
  const [name, setName] = useState('');
  const [takenName, setTakenName] = useState(false);
  const [predictionFiles, setPredictionFiles] = useState([]);
  const [filteredCount, setFilteredCount] = useState(0); // State to store the count of filtered items
  const navigate = useNavigate();

  useEffect(() => {
    const savedModels = JSON.parse(localStorage.getItem('savedModels')) || [];
    const storedFiles = JSON.parse(localStorage.getItem('uploadedPredFiles')) || [];
    setModels(savedModels);
    setPredictionFiles(storedFiles);
  }, []);

  const handleChangeModel = async (event) => {
    const modelName = event.target.value;
    setChoosenModelName(modelName);
    if(modelName) await filterFiles(modelName);
    else {
      const storedFiles = JSON.parse(localStorage.getItem('uploadedPredFiles')) || [];
      setPredictionFiles(storedFiles);
      setFilteredCount(0);
    }
  };

  const handleChangeFile = async (event) => {
    const storedFiles = JSON.parse(localStorage.getItem('uploadedPredFiles')) || [];
    const selectedFileData = storedFiles.find(file => file.name === event.target.value);
    setChoosenFile(await utils.FileRebuilder(selectedFileData));
  };

  const filterFiles = async (modelName) => {
    try {
      const chosenModel = await tf.loadLayersModel(`${MODEL_STORAGE_PATH}${modelName}`);
      const inputSize = chosenModel.layers[0].batchInputShape[1];
      const storedFiles = JSON.parse(localStorage.getItem('uploadedPredFiles')) || [];
      const filteredFiles = storedFiles.filter(file => file.colCount === inputSize);
      setPredictionFiles(filteredFiles);
      setFilteredCount(predictionFiles.length - filteredFiles.length); // Update the count of filtered items
    } catch (error) {
      console.error('Error loading model or filtering files:', error);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handlePrediction(choosenModelName);
  };

  const handlePrediction = async (modelName) => {
    try {
      const chosenModel = await tf.loadLayersModel(`${MODEL_STORAGE_PATH}${modelName}`);
      const predictionData = await utils.readExcelFile(choosenFile);
      const results = await Model.predict(chosenModel, predictionData);
      results.print()
      const resultsArray = await results.array();
      const predictionDate = new Date().toLocaleDateString();
      const rowCount = predictionData.length;
      let colCount;
      let outputArray = [];
      let averageAccuracy;
      // Checks if the file contains an Output column (assuming Output is the last column)
      if (LABEL_COLUMN_NAME === predictionData[0][predictionData[0].length - 1]) {
        colCount = predictionData[0].length - 1;
        for (let i = 1; i < predictionData.length; i++) {
          outputArray.push(predictionData[i][predictionData[0].length - 1]);
        }
        averageAccuracy = (await utils.calcAvgAccInPrecentages(outputArray, resultsArray.flat())).toString() + '%';
      } else {
        colCount = predictionData[0].length;
        averageAccuracy = 'NaN';
      }
      const predictionFileName = choosenFile.name;
      const allPredictions = JSON.parse(localStorage.getItem('predictions')) || [];
      let prediction = allPredictions.find(file => file.name === name);
      // If a prediction with the same name is found, notify the user
      if (!prediction) {
        const predictionInfo = {
          name,
          modelName,
          predictionDate,
          rowCount,
          colCount,
          resultsArray,
          predictionFileName,
          averageAccuracy
        };
        savePredictionInfo(predictionInfo);
        navigate('/predict');
      } else {
        setTakenName(true);
      }
    } catch (error) {
      console.error('Error during prediction:', error);
    }
  };

  const savePredictionInfo = (predictionInfo) => {
    const predictions = JSON.parse(localStorage.getItem('predictions')) || [];
    localStorage.setItem('predictions', JSON.stringify([...predictions, predictionInfo]));
  };

  return (
      <div className="flex items-center justify-center w-full mt-6">
        <div className="bg-slate-300 max-w-6xl sm:rounded-lg py-8 px-24 text-gray-800 flex flex-column items-start text-gray-600" >
          <form onSubmit={handleSubmit} className="sm:w-[600px]">
            <div className="flex flex-column items-start text-md">

              <div className="">
                  <label htmlFor="text" className="block text-sm font-medium leading-6 text-gray-900">
                    Name
                  </label>
                  <input
                    type="text"
                    id="text"
                    className="mt-1 rounded-md border-0 bg-white py-1 px-2 outline-none text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    />
              </div>

              <div className='pt-4 pb-1'>
                <label htmlFor="predictionDropdown" className="block text-sm font-medium leading-6 text-gray-900">
                  Select a Model
                </label>
                <select
                    id="predictionDropdown"
                    value={choosenModelName}
                    onChange={handleChangeModel}
                    className="mt-1 block w-full rounded-md border-0 bg-white py-2 pl-2 pr-10 text-gray-900 ring-1 ring-inset ring-gray-200 outline-none focus:ring-1 focus:ring-blue-600 sm:text-sm sm:leading-6"
                >
                  <option value="">Select...</option>
                  {models.map((modelInfo, index) => (
                    <option key={index} value={modelInfo.name}>
                      {modelInfo.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className='pt-4 pb-1'>
                <label htmlFor="fileDropdown" className="block text-sm font-medium leading-6 text-gray-900">
                  Select a File
                </label>
                <select
                    id="fileDropdown"
                    value={choosenModelName}
                    onChange={handleChangeFile}
                    className="mt-1 block w-full rounded-md border-0 bg-white py-2 pl-2 pr-10 text-gray-900 ring-1 ring-inset ring-gray-200 outline-none focus:ring-1 focus:ring-blue-600 sm:text-sm sm:leading-6"
                >
                  <option value="">Select...</option>
                  {predictionFiles.map((file, index) => (
                    <option key={index} value={file.name}>
                      {file.name}
                    </option>
                  ))}
                </select>
              </div>
              {takenName && (
                <h2 className='text-base leading-4 text-red-500 mt-3'>The prediction name you entered is already in use. Please choose a different name.</h2>
              )}
            </div>

            <button
              type="submit"
              className="block rounded-md bg-blue-600 mt-4 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
              Submit
            </button>

            <p className='pt-2 text-sm'>{filteredCount} files didn't match the selected model and were removed</p>
          </form>
      </div>
    </div>
  );
};

export default PredictionForm;
