import React, { useState, useRef } from 'react';
import ExplanationTrainPopup from './ExplanationTrainPopup';
import ExplanationPredictPopup from './ExplanationPredictPopup';

const FileUpload = ({ onFileUpload, onCancel, predictionDataBool }) => {
  const [dragging, setDragging] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showFileInput, setShowFileInput] = useState(true);
  const fileInputRef = useRef(null);

  const handleDragEnter = (event) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setDragging(false);
    const file = event.dataTransfer.files[0];
    handleFile(file);
  };

  const handleFileChange = (event) => {
    if (showPopup) return; // If popup is open, do not process file
    const file = event.target.files[0];
    handleFile(file);
  };

  const handleFile = (file) => {
    if (file) {
      const fileName = file.name.toLowerCase();
      if (fileName.endsWith('.xls') || fileName.endsWith('.xlsx')) {
        onFileUpload(file);
      } else {
        alert('Please upload a valid Excel file.');
      }
    }
  };

  const togglePopup = () => {
    setShowPopup(!showPopup)
    setShowFileInput(!showFileInput)
  };

  const handleLearnMoreClick = (event) => {
    event.preventDefault(); 
    togglePopup();
  };

  return (
    <div className="bg-white mt-4">
      {showFileInput && (
        <div className='bg-slate-300 lg:rounded-lg pt-10 pb-2 lg:mx-4 text-gray-800 text-center flex flex-column items-center'> 
          <div
            className={`drop-zone ${dragging ? 'dragging' : ''} bg-white flex flex-column justify-center items-center px-20 py-16 rounded-md`}
            onDragOver={(e) => e.preventDefault()}
            onDragEnter={handleDragEnter}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={() => fileInputRef.current.click()} 
            data-testid="drop-zone"
          >
            <input
              type="file"
              className="file-input visually-hidden"
              accept=".xls,.xlsx"
              onChange={handleFileChange}
              ref={fileInputRef} 
            />
            <label htmlFor="file" className="text-gray-800 text-lg text-center">
              Drag Your Data Here<br/>
            </label>
          </div>
          <div className="mt-2 text-sm">
            <button className="bg-transparent border-none p-0 underline pointer text-blue-700" onClick={handleLearnMoreClick}>
            Learn more</button><span> about our data format</span>
          </div>
          <div className="flex justify-center my-4">
            <button className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 self-center" onClick={onCancel}> 
              Back To The Table
            </button>
          </div>
        </div>
      )}
      {showPopup && (
        <div className="popup">
          <div className="flex flex-column popup-content text-justify bg-slate-300 rounded-lg py-4 px-8">
            {!predictionDataBool ? (
              <ExplanationTrainPopup />
            ) : (
              <ExplanationPredictPopup />
            )}
            <button className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 self-center" onClick={togglePopup}>Close Popup</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FileUpload;
