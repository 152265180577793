import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import FileUpload from '../Forms/FileUpload';
import * as XLSX from 'xlsx';
import utils from '../../tensorflow/utils';
import { PencilSquareIcon } from '@heroicons/react/24/outline'

const DataTable = ({ predictionDataBool }) => {
  const [showFileInput, setShowFileInput] = useState(false);
  const [validTrainingFile, setValidTrainingFile] = useState(true);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [editIndex, setEditIndex] = useState(-1); 
  const [editedName, setEditedName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    let storedFiles
    if(predictionDataBool === false) {
      storedFiles = JSON.parse(localStorage.getItem('uploadedFiles'));
    } else {
      storedFiles = JSON.parse(localStorage.getItem('uploadedPredFiles'));
    }
    if (storedFiles) {
      setUploadedFiles(storedFiles);
    }
  }, [predictionDataBool]);

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const fileContent = event.target.result;
      const workbook = XLSX.read(fileContent, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const rowCount = sheet['!ref'].split(':')[1].slice(1);
      let columnCount = XLSX.utils.decode_range(sheet['!ref']).e.c + 1;
      const firstRow = XLSX.utils.sheet_to_json(sheet, { header: 1, range: 'A1:Z1' })[0];

      if (predictionDataBool === true && firstRow.includes("Output")) { // if the file is a prediction file and has an Output column it's reduced from the count
        columnCount--;
      } else if (predictionDataBool === false && !(firstRow.includes("Output"))){
        setValidTrainingFile(false);
        return;
      }
      const newFile = {
        name: file.name,
        date: new Date().toLocaleDateString(),
        rowCount: rowCount,
        colCount: columnCount,
        content: fileContent 
      };
      const updatedFiles = [...uploadedFiles, newFile];
      setUploadedFiles(updatedFiles);
      // TODO: Extract function and use 3 times:
      if(!predictionDataBool) {
        localStorage.setItem('uploadedFiles', JSON.stringify(updatedFiles));
      } else {
        localStorage.setItem('uploadedPredFiles', JSON.stringify(updatedFiles));
      }
      setShowFileInput(false);
    };
    reader.readAsBinaryString(file);
  };  

  const handleRemove = (index) => {
    const updatedFiles = uploadedFiles.filter((_, i) => i !== index);
    setUploadedFiles(updatedFiles);
    if(!predictionDataBool) {
      localStorage.setItem('uploadedFiles', JSON.stringify(updatedFiles));
    } else {
      localStorage.setItem('uploadedPredFiles', JSON.stringify(updatedFiles));
    }
  };

  const viewData = async (fileName) => {
    const selectedFileData = uploadedFiles.find(file => file.name === fileName);
    const file = await utils.FileRebuilder(selectedFileData);
    if(!predictionDataBool) navigate(`/train/data-view?filename=${fileName}`, { state: { excelFile: file} });
    else navigate(`/predict/data-view?filename=${fileName}`, { state: { excelFile: file} });
  };

  const editName = (index, name) => {
    setEditIndex(index);
    setEditedName(name);
  };

  const handleNameChange = (e) => {
    setEditedName(e.target.value);
  };

  const saveEditedName = (index) => {
    const updatedFiles = [...uploadedFiles];
    updatedFiles[index].name = editedName;
    setUploadedFiles(updatedFiles);
    if(!predictionDataBool) {
      localStorage.setItem('uploadedFiles', JSON.stringify(updatedFiles));
    } else {
      localStorage.setItem('uploadedPredFiles', JSON.stringify(updatedFiles));
    }
    setEditIndex(-1);
    setEditedName('');
  };

  const cancelEditName = () => {
    setEditIndex(-1);
    setEditedName('');
  };

  const resetUpload = () => {
    setShowFileInput(true);
    setValidTrainingFile(true);
  };

  return (
    <div className="max-w-6xl mx-auto items-start" data-testid="drop-zone">
      {showFileInput ? (
        <>
          <FileUpload onFileUpload={handleFileUpload} onCancel={() => setShowFileInput(false)} predictionDataBool={predictionDataBool} validTrainingFile={validTrainingFile}/>
          {!validTrainingFile && (
            <h2 style={{color: 'red'}}>The training file is missing an "Output" column</h2>
          )}
        </>
      ) : (
        <div className="my-2 flex flex-column items-start">
          <div className="inline-block min-w-full w-full py-4 align-middle sm:px-6 lg:px-8">
            <div className="shadow overflow-x-auto ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                      <span className="sr-only">Row ID</span>
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      File Name
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Date
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Rows
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Columns
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      <span className="sr-only">View Data</span>
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4">
                      <span className="sr-only">Remove</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {uploadedFiles.map((file, index) => (
                    <tr key={index}>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">{index + 1}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 w-[330px] text-left">
                        {editIndex === index ? (
                          <div className="flex items-center justify-between">
                            <input
                              className="w-[180px] outline-none block w-full rounded-md border-0 px-2 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              type="text"
                              value={editedName}
                              onChange={handleNameChange}
                            />
                            <div className="flex items-center">
                              <button className="text-blue-600 hover:text-blue-900 mx-1 px-1" onClick={() => saveEditedName(index)}>
                                Save
                              </button>
                              <button className="text-red-600 hover:text-red-900 mx-1 px-1" onClick={cancelEditName}>
                                Cancel
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="flex items-center">
                            {file.name}
                            <button className="btn" data-twe-toggle="tooltip" title="Edit File Name" onClick={() => editName(index, file.name)}>
                              <PencilSquareIcon className="w-5 h-5" alt="Edit" />
                            </button>
                          </div>
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">{file.date}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">{file.rowCount}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">{file.colCount}</td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 text-left">
                        <button className="text-blue-600 hover:text-blue-900 mx-1 px-1" onClick={() => viewData(file.name)}>
                          View Data
                        </button>
                      </td>
                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 text-left">
                        <button className="text-red-600 hover:text-red-900 mx-1 px-1" onClick={() => handleRemove(index)}>
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <button className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 self-center"
            onClick={() => resetUpload()}>
              Upload Data File
          </button>
        </div>
      )}
    </div>
  );
};

export default DataTable;
