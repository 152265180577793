import React, { useState, useEffect } from 'react';
import PredictionTableRow from './PredictionTableRow';
import utils from '../../tensorflow/utils';
import { useNavigate } from 'react-router-dom';

const PredictionTable = () => {
  const [predictions, setPredictions] = useState([]);
  const navigate = useNavigate();

  const uploadedFiles = localStorage.getItem('uploadedPredFiles')
    ? JSON.parse(localStorage.getItem('uploadedPredFiles'))
    : [];

  useEffect(() => {
    const storedPredictions = JSON.parse(localStorage.getItem('predictions')) || [];
    setPredictions(storedPredictions);
  }, []);

  const makePrediction = () => {
    navigate('/predict/prediction-form');
  };

  const removePrediction = (name) => {
    const updatedPredictions = predictions.filter(prediction => prediction.name !== name);
    setPredictions(updatedPredictions);
    localStorage.setItem('predictions', JSON.stringify(updatedPredictions));
  };

  const viewResults = async (predictionFileName, resultsArray) => {
    const selectedFileData = uploadedFiles.find(file => file.name === predictionFileName);
    const file = await utils.FileRebuilder(selectedFileData);
    navigate(`/predict/prediction-results?filename=${predictionFileName}`, { state: { excelFile: file, predictionArray: resultsArray } });
  };

  return ( 
    <div className="my-2 flex flex-column max-w-6xl mx-auto items-center">
      <div className="inline-block min-w-full w-full py-4 align-middle sm:px-6 lg:px-8">
        <div className="overflow-hidden overflow-x-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                <span className="sr-only">Row ID</span>
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Name
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Date
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Model
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Rows
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Number of Required Columns
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  Average Accuracy
                </th>
                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                  <span className="sr-only">View Results</span>
                </th>
                <th scope="col" className="relative py-3.5 pl-3 pr-4">
                  <span className="sr-only">Remove</span>
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {predictions.map((prediction, index) => (
                <PredictionTableRow
                  key={index}
                  prediction={prediction}
                  viewResults={() => viewResults(prediction.predictionFileName, prediction.resultsArray.flat() )}
                  removePrediction={removePrediction}
                  index={index + 1}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <button className="block rounded-md bg-blue-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600" onClick={makePrediction}>
        Make a Prediction
      </button>
    </div>
  );
};

export default PredictionTable;
