import { CheckIcon } from '@heroicons/react/20/solid'

const ExplanationPredictPopup = () => {

  return (
    <div className="mb-4 text-base">
        <p className='text-base font-medium mb-2'>Supported File Format:</p>
        <p className='text-sm mb-4'>The tool accepts Excel (.xlsx) files for uploading prediction data.</p>
        <p className='text-base font-medium mb-2'>Data Structure:</p>
        <ul className='text-sm pl-1'>
          <li className="flex gap-x-3">
            <CheckIcon className="mt-1 h-4 w-4 flex-none text-blue-600" aria-hidden="true" />
            <span>Each row represents a single data point for which you want a prediction (e.g., a new applicant).</span>
          </li>
          <li className="flex gap-x-3">
            <CheckIcon className="mt-1 h-4 w-4 flex-none text-blue-600" aria-hidden="true" />
            <span>Each column represents an attribute of the data point (e.g., exam grade).</span>
          </li>
          <li className="flex gap-x-3">
            <CheckIcon className="mt-1 h-4 w-4 flex-none text-blue-600" aria-hidden="true" />
            <span>The first row of the file should contain column headers.</span>
          </li>
          <li className="flex gap-x-3">
            <CheckIcon className="mt-1 h-4 w-4 flex-none text-blue-600" aria-hidden="true" />
            <span>The last column must be named "Output" and contain the target value you want the model to predict (e.g. job placement status or salary).</span>
          </li>
        </ul>
        <p className='text-base font-medium mb-2'>Optional "Output" Column:</p>
        <ul className='text-sm pl-1'>
          <li className="flex gap-x-3">
            <CheckIcon className="mt-1 h-4 w-4 flex-none text-blue-600" aria-hidden="true" />
            <span>You can optionally include an additional column named "Output" in your prediction data.</span>
          </li>
          <li className="flex gap-x-3">
            <CheckIcon className="mt-1 h-4 w-4 flex-none text-blue-600" aria-hidden="true" />
            <span>This column can contain the actual target value (e.g., job placement status or salary) if you have it available.</span>
          </li>
          <li className="flex gap-x-3">
            <CheckIcon className="mt-1 h-4 w-4 flex-none text-blue-600" aria-hidden="true" />
            <span>Including the "Output" column allows you to compare the model's prediction to the actual value, providing insights into model performance.</span>
          </li>
        </ul>
    </div>
  );
}

export default ExplanationPredictPopup;
