import React, { useState } from 'react';
import { PencilSquareIcon } from '@heroicons/react/24/outline'

const PredictionTableRow = ({ prediction, viewResults, removePrediction, index }) => {
  const [editedName, setEditedName] = useState(prediction.name);
  const [isEditing, setIsEditing] = useState(false);

  const handleRemovePrediction = () => {
    removePrediction(prediction.name);
  };

  const handleViewResults = () => {
    viewResults(prediction.predictionFileName, prediction.resultsArray.flat());
  };

  const handleEditName = () => {
    setIsEditing(true);
  };

  const handleSaveName = () => {
    const predictionsFromStorage = JSON.parse(localStorage.getItem('predictions')) || [];
    const predictionIndex = predictionsFromStorage.findIndex(pred => pred.name === prediction.name);
    if (predictionIndex !== -1) {
      predictionsFromStorage[predictionIndex].name = editedName;
      localStorage.setItem('predictions', JSON.stringify(predictionsFromStorage));
    }
    prediction.name = editedName;
    setIsEditing(false);
  };

  const handleCancelEdit = () => {
    setEditedName(prediction.modelName);
    setIsEditing(false);
  };

  const handleNameChange = (e) => {
    setEditedName(e.target.value);
  };

  return (
    <tr key={index}>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">{index}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900 w-[300px] text-left">
        {isEditing ? (
          <div className="flex items-center justify-between">
            <input
              className="w-[150px] outline-none block w-full rounded-md border-0 px-2 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
              type="text"
              value={editedName}
              onChange={handleNameChange}
            />
            <button className="text-blue-600 hover:text-blue-900 mx-1 px-1" onClick={handleSaveName}>
              Save
            </button>
            <button className="text-red-600 hover:text-red-900 mx-1 px-1" onClick={handleCancelEdit}>
              Cancel
            </button>
          </div>
        ) : (
          <div className="flex items-center">
            {prediction.name}
            <button className="btn" data-twe-toggle="tooltip" title="Edit Prediction Table Name" onClick={handleEditName}>
              <PencilSquareIcon className="w-5 h-5" alt="Edit" />
            </button>
          </div>
        )}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">{prediction.predictionDate}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">{prediction.modelName}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">{prediction.rowCount}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">{prediction.colCount}</td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-left">{prediction.averageAccuracy}</td>
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 text-left">
        <button className="text-blue-600 hover:text-blue-900" onClick={handleViewResults}>
          View Results
        </button>
      </td>
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 text-left">
        <button className="text-red-600 hover:text-red-900 mx-1 px-1" onClick={handleRemovePrediction}>
          Remove
        </button>
      </td>
    </tr>
  );
};

export default PredictionTableRow;
